import React from 'react';
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import BackgroundImage from 'gatsby-background-image';

const useStyles = makeStyles(theme => ({
    hero: {
        color: theme.palette.common.white,
    },
    heroOverlay: {
        background: 'rgba(1,1,1,0.5)',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    }
}));


export default function Header({heroImage, heroHeadline1, heroHeadline2}) {
    const classes = useStyles();

    return (
        <Paper className={classes.hero} square={true}>
            <BackgroundImage fluid={heroImage}>
                <Grid container justify="flex-end">
                    <Grid item md={6}>
                        <div className={classes.heroOverlay}>
                            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                                {heroHeadline1}
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                {heroHeadline2}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </BackgroundImage>
        </Paper>
    );
}

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import PhoneIcon from '@material-ui/icons/Phone';
import FaxIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import Link from './Link';

const useStyles = makeStyles(theme => ({
    footer: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#9f9f9f',
        paddingTop: '5px',
        marginTop: 'auto',
        padding: theme.spacing(6, 0),
    },
    footerlink: {
        color: '#000',
    }
}));

export default function Footer() {
    const classes = useStyles();
    return (

        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={3}
                    alignItems="flex-end"
                >
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Adresse
                        </Typography>
                        <p>
                            Dreyer & Pfeiffer Rechtsanwälte GbR<br/>
                            Ridlerstrasse 33<br/>
                            80339 München<br/>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <p>
                            <PhoneIcon className={classes.icon} fontSize="small"/>&nbsp;
                            <a className={classes.footerlink + " MuiLink-root MuiLink-underlineHover"}
                               href="tel:+498924206960">
                                089-242069-60
                            </a>
                            <br/>

                            <FaxIcon className={classes.icon} fontSize="small"/>&nbsp;
                            <a className={classes.footerlink + " MuiLink-root MuiLink-underlineHover"}
                               href="tel:+498924206969">
                                089-242069-69
                            </a>
                            <br/>

                            <EmailIcon className={classes.icon} fontSize="small"/>&nbsp;
                            <a className={classes.footerlink + " MuiLink-root MuiLink-underlineHover"}
                               href="mailto:kanzlei@dreyer-pfeiffer.de">
                                kanzlei@dreyer-pfeiffer.de
                            </a>
                            <br/>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>

                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Bürozeiten
                        </Typography>
                        <p>
                            Mo bis Fr 08.30 bis 12 Uhr<br/>
                            Mo bis Do 13.30 bis 16.30 Uhr<br/>
                            <br/>
                        </p>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid
                    spacing={3}// Add it here :)
                    container
                >
                    <Grid item xs={12}>
                        <Typography align="center">
                            {/*<Link*/}
                            {/*    color="inherit"*/}
                            {/*    key={2}*/}
                            {/*    variant="button"*/}
                            {/*    href="#"*/}
                            {/*    className={classes.navLink}*/}
                            {/*    to="/kontakt"*/}
                            {/*>*/}
                            {/*    Kontakt*/}
                            {/*</Link>*/}
                            {/*&nbsp;|&nbsp;*/}
                            <Link
                                color="inherit"
                                key={1}
                                variant="button"
                                href="#"
                                className={classes.navLink}
                                to="/kontakt"
                            >
                                Anfahrt
                            </Link>
                            &nbsp;|&nbsp;
                            <Link
                                color="inherit"
                                key={2}
                                variant="button"
                                href="#"
                                className={classes.navLink}
                                to="/impressum"
                            >
                                Impressum
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </footer>

    );
}

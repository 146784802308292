import React from 'react';
import {Helmet} from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import Hero from "./Hero";

export default function App({children, heroHeadline1, heroHeadline2, heroImage}) {

    return (
        <React.Fragment>
            <Helmet
                key="app-head"
                titleTemplate="%s · Dreyer & Pfeiffer"
                defaultTitle="Dreyer & Pfeiffer"
            >
                <html lang="de" />

                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="referrer" content="no-referrer" />
                <title>Dreyer & Pfeiffer</title>
                <meta name="description" content="Spezialisten für Baurecht, Verwaltungsrecht und Verkehrsrecht" />
            </Helmet>
            <Header/>
            <Hero
                heroImage={heroImage}
                heroHeadline1={heroHeadline1}
                heroHeadline2={heroHeadline2}
            />
            {children}
            <Footer/>
        </React.Fragment>
    )
}

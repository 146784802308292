import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Link from './Link';
import {Hidden} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff'
    },
    logo: {
        marginTop: "0px",
        marginLeft: "10px",
        maxWidth: "170px",
        flexGrow: 1,

    },
    toolbarTopTitle: {
        flexGrow: 1,
    },
    icon: {
        margin: theme.spacing(1),
    },
    navLink: {
        margin: theme.spacing(1, 1.5),
    },
    toolbarSecondary: {
        overflowX: 'auto',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#9f9f9f',
        paddingTop: '5px',
    },
    callbackCta: {
        margin: theme.spacing(1),
        boxShadow: 'none',
    },
}));


export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Toolbar className={classes.toolbarTop}>
                <Link to="/">
                    <img src="/images/dreyer-pfeiffer-logo.svg" alt="Logo" className={classes.logo}/>
                </Link>
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTopTitle}>
                </Typography>

                <Hidden xsDown>
                    <PhoneIcon className={classes.icon} fontSize="small"/>
                    <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                       href="tel:+498924206960">
                        089-242069-60
                    </a>
                    <EmailIcon className={classes.icon} fontSize="small"/>
                    <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                       href="mailto:kanzlei@dreyer-pfeiffer.de">
                        kanzlei@dreyer-pfeiffer.de
                    </a>
                </Hidden>
                <Hidden smUp>
                    <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover"
                       style={{color: 'black'}}
                       href="tel:+498924206960">
                        <PhoneIcon className={classes.icon} fontSize="large" />
                    </a>
                    <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover"
                       style={{color: 'black'}}
                       href="mailto:kanzlei@dreyer-pfeiffer.de">
                        <EmailIcon className={classes.icon} fontSize="large" />
                    </a>
                </Hidden>

            </Toolbar>
            <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                <Grid
                    justify="space-between" // Add it here :)
                    alignItems="center"
                    container
                >
                    <Grid item>

                    </Grid>
                    <Grid item>

                        <Link
                            color="inherit"
                            key={1}
                            variant="button"
                            href="#"
                            className={classes.navLink}
                            to="/"
                        >
                            Startseite
                        </Link>

                        <Link
                            color="inherit"
                            key={2}
                            variant="button"
                            href="#"
                            className={classes.navLink}
                            to="/kanzlei"
                        >
                            Kanzlei
                        </Link>

                        <Link
                            color="inherit"
                            key={3}
                            variant="button"
                            href="#"
                            className={classes.navLink}
                            to="/rechtsgebiete"
                        >
                            Rechtsgebiete
                        </Link>

                        <Link
                            color="inherit"
                            key={4}
                            variant="button"
                            href="#"
                            className={classes.navLink}
                            to="/team"
                        >
                            Anwälte
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </div>
    );
}
